<template>
  <v-container
    class="px-sm-10 py-sm-10"
    fluid
    style="min-height: 100vh; background: #e6e6e6"
  >
    <v-tabs v-model="tab" color="#36AC87">
      <v-tab key="profesional" class="text-capitalize"> Profesional </v-tab>
      <v-tab key="universitas" class="text-capitalize"> Universitas </v-tab>
      <!-- <v-tab
        v-if="isAllowVerifyPayment"
        key="pembayaran"
        class="text-capitalize"
      >
        Verifikasi Dokumen
      </v-tab> -->
      <v-tab v-if="isAllowVerifyData" key="verifikasi" class="text-capitalize">
        Verifikasi Data Anggota
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" touchless>
      <v-tab-item key="profesional">
        <v-card class="px-2 mx-2" elevation="0">
          <v-data-table
            :headers="headers"
            :items="dataTable"
            :items-per-page="defaultPerPage"
            class="elevation-0"
            mobile-breakpoint="100"
            hide-default-footer
          >
            <template v-slot:top>
              <v-row>
                <v-col cols="12" sm="5" class="mt-4 px-8 pt-4 pb-10">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Cari"
                    color="#36AC87"
                    single-line
                    hide-details
                    clearable
                    outlined
                    dense
                    @keyup="filterData"
                    @click:append="filterData"
                    @click:clear="filterClear"
                  />
                </v-col>
              </v-row>
            </template>

            <!-- <template v-slot:[`item.status_string`]="{ item }">
              <v-chip :color="'#' + item.status_color">
                <span style="color: #FFFFFF">
                  {{ item.status_string }}
                </span>
              </v-chip>
            </template> -->

            <template v-slot:[`item.actions`]="{ item }">
              <div
                class="action-container"
                style="
                  width: 36px;
                  height: 29px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border: 1px solid #f2f2f2;
                  box-sizing: border-box;
                  border-radius: 4px;
                  cursor: pointer;
                "
                @click="toDetail(item)"
              >
                <v-icon class="action-icon"> mdi-eye </v-icon>
              </div>
            </template>

            <template v-slot:footer>
              <div class="text-center my-5 py-3">
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  color="#2E976C"
                  :total-visible="5"
                  @input="onChangePage"
                />
                <span style="font-size: 12px; font-style: italic">
                  {{ itemCount + " dari " + itemTotal + " ditampilkan" }}
                </span>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>

      <v-tab-item key="universitas">
        <v-card class="px-2 mx-2" elevation="0">
          <v-data-table
            :headers="headers2"
            :items="dataTable2"
            :items-per-page="defaultPerPage"
            class="elevation-0"
            mobile-breakpoint="100"
            hide-default-footer
          >
            <template v-slot:top>
              <v-row>
                <v-col cols="12" sm="5" class="mt-4 px-8 pt-4 pb-10">
                  <v-text-field
                    v-model="search2"
                    append-icon="mdi-magnify"
                    label="Cari"
                    color="#36AC87"
                    single-line
                    hide-details
                    clearable
                    outlined
                    dense
                    @keyup="filterData2"
                    @click:append="filterData2"
                    @click:clear="filterClear2"
                  />
                </v-col>
              </v-row>
            </template>

            <!-- <template v-slot:[`item.status_string`]="{ item }">
              <v-chip :color="'#' + item.status_color">
                <span style="color: #FFFFFF">
                  {{ item.status_string }}
                </span>
              </v-chip>
            </template> -->

            <template v-slot:[`item.actions`]="{ item }">
              <div
                class="action-container"
                style="
                  width: 36px;
                  height: 29px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border: 1px solid #f2f2f2;
                  box-sizing: border-box;
                  border-radius: 4px;
                  cursor: pointer;
                "
                @click="toDetail(item)"
              >
                <v-icon class="action-icon"> mdi-eye </v-icon>
              </div>
            </template>

            <template v-slot:footer>
              <div class="text-center my-5 py-3">
                <v-pagination
                  v-model="page2"
                  :length="pageCount2"
                  color="#2E976C"
                  :total-visible="5"
                  @input="onChangePage2"
                />
                <span style="font-size: 12px; font-style: italic">
                  {{ itemCount2 + " dari " + itemTotal2 + " ditampilkan" }}
                </span>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>

      <!-- <v-tab-item v-if="isAllowVerifyPayment" key="pembayaran">
        <v-card class="px-2 mx-2" elevation="0">
          <v-data-table
            :headers="headers4"
            :items="dataTable4"
            :items-per-page="defaultPerPage"
            class="elevation-0"
            mobile-breakpoint="100"
            hide-default-footer
          >
            <template v-slot:top>
              <v-row>
                <v-col cols="12" sm="5" class="mt-4 px-8 pt-4 pb-10">
                  <v-text-field
                    v-model="search4"
                    append-icon="mdi-magnify"
                    label="Cari"
                    color="#36AC87"
                    single-line
                    hide-details
                    clearable
                    outlined
                    dense
                    @keyup="filterData4"
                    @click:append="filterData4"
                    @click:clear="filterClear4"
                  />
                </v-col>
              </v-row>
            </template>

            <template v-slot:[`item.status_string`]="{ item }">
              <v-chip :color="'#' + item.status_color">
                <span style="color: #FFFFFF">
                  {{ item.status_string }}
                </span>
              </v-chip>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <div
                class="action-container"
                style="
                  width: 36px;
                  height: 29px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border: 1px solid #f2f2f2;
                  box-sizing: border-box;
                  border-radius: 4px;
                  cursor: pointer;
                "
                @click="toPaymentDetail(item)"
              >
                <v-icon class="action-icon"> mdi-eye </v-icon>
              </div>
            </template>

            <template v-slot:footer>
              <div class="text-center my-5 py-3">
                <v-pagination
                  v-model="page4"
                  :length="pageCount4"
                  color="#2E976C"
                  :total-visible="5"
                  @input="onChangePage4"
                />
                <span style="font-size: 12px; font-style: italic">
                  {{ itemCount4 + " dari " + itemTotal4 + " ditampilkan" }}
                </span>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item> -->

      <v-tab-item v-if="isAllowVerifyData" key="verifikasi">
        <v-card class="px-2 mx-2" elevation="0">
          <v-data-table
            :headers="headers3"
            :items="dataTable3"
            class="elevation-0"
            :items-per-page="defaultPerPage"
            mobile-breakpoint="100"
            hide-default-footer
          >
            <template v-slot:top>
              <v-row>
                <v-col cols="12" sm="5" class="mt-4 px-8 pt-4 pb-10">
                  <v-text-field
                    v-model="search3"
                    append-icon="mdi-magnify"
                    label="Cari"
                    color="#36AC87"
                    single-line
                    hide-details
                    clearable
                    outlined
                    dense
                    @keyup="filterData3"
                    @click:append="filterData3"
                    @click:clear="filterClear3"
                  />
                </v-col>
              </v-row>
            </template>

            <!-- <template v-slot:[`item.status_string`]="{ item }">
              <v-chip :color="'#' + item.status_color">
                <span style="color: #FFFFFF">
                  {{ item.status_string }}
                </span>
              </v-chip>
            </template> -->

            <template v-slot:[`item.actions`]="{ item }">
              <div
                class="action-container"
                style="
                  width: 36px;
                  height: 29px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border: 1px solid #f2f2f2;
                  box-sizing: border-box;
                  border-radius: 4px;
                  cursor: pointer;
                "
                @click="toDetail(item, true)"
              >
                <v-icon class="action-icon"> mdi-eye </v-icon>
              </div>
            </template>

            <template v-slot:footer>
              <div class="text-center my-5 py-3">
                <v-pagination
                  v-model="page3"
                  :length="pageCount3"
                  color="#2E976C"
                  :total-visible="5"
                  @input="onChangePage3"
                />
                <span style="font-size: 12px; font-style: italic">
                  {{ itemCount3 + " dari " + itemTotal3 + " ditampilkan" }}
                </span>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <v-dialog v-model="dialog" max-width="600">
      <v-card class="px-8 py-6">
        <v-row>
          <v-col>
            <span style="font-weight: bold"> Detail Verifikasi Dokumen </span>
          </v-col>

          <v-col class="text-right">
            <span style="cursor: pointer" @click="dialog = false"> X </span>
          </v-col>
        </v-row>
        <template v-if="detailPayment !== null">
          <v-row>
            <v-col class="txt16-black1" cols="4"> Nama </v-col>
            <v-col class="txt16-gray50" cols="8">
              {{ detailPayment.name || "-" }}
            </v-col>
          </v-row>

          <v-row>
            <v-col class="txt16-black1" cols="4"> Telepon </v-col>
            <v-col class="txt16-gray50" cols="8">
              {{ detailPayment.phone || "-" }}
            </v-col>
          </v-row>

          <v-row>
            <v-col class="txt16-black1" cols="4"> Perusahaan </v-col>
            <v-col class="txt16-gray50" cols="8">
              {{ detailPayment.comp_or_univ || "-" }}
            </v-col>
          </v-row>

          <v-row>
            <v-col class="txt16-black1" cols="4"> BPC </v-col>
            <v-col class="txt16-gray50" cols="8">
              {{ detailPayment.bpc || "-" }}
            </v-col>
          </v-row>

          <v-row>
            <v-col class="txt16-black1" cols="4"> Surat Rekomendasi </v-col>
            <v-col class="txt16-gray50" cols="8">
              <v-img
                :src="detailPayment.file_payment.url_thumb"
                contain
                @click="showImageDetail(detailPayment.file_payment.url)"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <hr />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="txt16-black1"> Catatan </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-textarea
                ref="reason"
                v-model="rejectReason"
                placeholder="Catatan"
                rows="4"
                color="#32A179"
                no-resize
                outlined
              />
            </v-col>
          </v-row>

          <v-row class="mt-n12">
            <v-col class="txt12-gray50">
              *Harus diisi jika dokumen yang belum sesuai
            </v-col>
          </v-row>

          <v-row class="mt-10">
            <v-col class="text-right">
              <v-btn
                class="text-capitalize mr-2"
                color="#2E976C"
                style="color: white"
                @click="accept"
              >
                Verifikasi
              </v-btn>

              <v-btn
                class="text-capitalize"
                color="#EA3E3A"
                style="color: white"
                @click="reject"
              >
                Belum Sesuai
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-card>
    </v-dialog>

    <modal-konfirmasi :show="modal" @submit="confirm" />
  </v-container>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import ModalKonfirmasi from "../komponen/modal/ModalKonfirmasi.vue";
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.defaults.headers.post["x-api-key"] = localStorage.getItem("token");

export default {
  components: {
    ModalKonfirmasi,
  },

  data: () => ({
    tab: null,
    tabDialog: null,
    dialog: false,
    defaultPerPage: 100,

    search: "",
    headers: [
      { text: "Nomor KTA", align: "start", value: "no_kta", sortable: false },
      { text: "Nama", value: "name", sortable: false },
      { text: "Telepon", value: "phone", sortable: false },
      { text: "Perusahaan", value: "com_or_univ" },
      { text: "BPC", value: "bpc" },
      { text: "Status", value: "member_status_string", sortable: false },
      { text: "", value: "actions" },
    ],
    dataTable: [],
    page: 1,
    pageCount: 0,
    itemCount: 0,
    itemTotal: 0,

    search2: "",
    headers2: [
      { text: "Nomor KTA", align: "start", value: "no_kta", sortable: false },
      { text: "Nama", value: "name", sortable: false },
      { text: "Telepon", value: "phone", sortable: false },
      { text: "Universitas", value: "com_or_univ" },
      { text: "BPC", value: "bpc" },
      { text: "Status", value: "member_status_string", sortable: false },
      { text: "", value: "actions" },
    ],
    dataTable2: [],
    page2: 1,
    pageCount2: 0,
    itemCount2: 0,
    itemTotal2: 0,

    search3: "",
    headers3: [
      // { text: "Nomer KTA", align: "start", value: "no_kta", sortable: false },
      { text: "Nama", value: "name", sortable: false },
      { text: "Telepon", value: "phone", sortable: false },
      { text: "Perusahaan / Universitas", value: "com_or_univ", sortable: false },
      { text: "BPC", value: "bpc", sortable: false },
      { text: "Tanggal Daftar", value: "created_at", sortable: false },
      { text: "", value: "actions", sortable: false },
    ],
    dataTable3: [],
    page3: 1,
    pageCount3: 0,
    itemCount3: 0,
    itemTotal3: 0,

    search4: "",
    headers4: [
      { text: "Nama", value: "name", sortable: false },
      { text: "Telepon", value: "phone", sortable: false },
      { text: "Perusahaan", value: "com_or_univ" },
      { text: "BPC", value: "bpc" },
      { text: "", value: "actions" },
    ],
    dataTable4: [],
    page4: 1,
    pageCount4: 0,
    itemCount4: 0,
    itemTotal4: 0,

    detailPayment: null,
    rejectReason: "",
    modal: false,
    acceptReject: 0,

    tmp: null,
    isAllowVerifyData: false,
    isAllowVerifyPayment: false,
  }),

  computed: {
    ...mapState({
      itemsPerPage: (state) => state.setting.itemsPerPage,
    }),
  },

  watch: {},

  created() {
    this.initialize(1, 1);
    this.initialize(1, 2);
    this.initialize(1, 3);
    // this.initialize(1, 4);
  },

  methods: {
    initialize(page, type, search = null) {
      const userInfo = JSON.parse(localStorage.getItem("userinfo"));
      const userMenus = userInfo.menu_function;
      if (userMenus.includes("MEMBER_VERIFY_DATA"))
        this.isAllowVerifyData = true;
      // if (userMenus.includes("MEMBER_VERIFY_PAYMENT"))
      //   this.isAllowVerifyPayment = true;

      const requestBody = {
        type: type,
        page: page,
        perpage: this.defaultPerPage,
        search: search,
      };

      axios
        .post("/v1/admin/member", requestBody)
        .then((res) => {
          if (res.data.status === 200) {
            switch (type) {
              case 1: //professional
                this.dataTable = res.data.data.member.list;
                this.pageCount = res.data.data.member.pagination.last_page;
                this.itemCount = res.data.data.member.pagination.count;
                this.itemTotal = res.data.data.member.pagination.total;
                break;

              case 2: //universitas
                this.dataTable2 = res.data.data.member.list;
                this.pageCount2 = res.data.data.member.pagination.last_page;
                this.itemCount2 = res.data.data.member.pagination.count;
                this.itemTotal2 = res.data.data.member.pagination.total;
                break;

              case 3: //verifikasi data anggota
                this.dataTable3 = res.data.data.member.list;
                this.pageCount3 = res.data.data.member.pagination.last_page;
                this.itemCount3 = res.data.data.member.pagination.count;
                this.itemTotal3 = res.data.data.member.pagination.total;
                break;

              case 4:
                this.dataTable4 = res.data.data.member.list;
                this.pageCount4 = res.data.data.member.pagination.last_page;
                this.itemCount4 = res.data.data.member.pagination.count;
                this.itemTotal4 = res.data.data.member.pagination.total;
                break;
            }
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0][0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },

    getPaymentDetail(p) {
      const requestBody = {
        m_hash: p,
      };

      axios
        .post("/v1/admin/member/detail/verify-payment", requestBody)
        .then((res) => {
          if (res.data.status === 200) {
            this.detailPayment = res.data.data;
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0][0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },

    toDetail(item, verification = false) {
      // console.log(item)
      if (verification) {
        this.$router.push({
          name: "KeanggotaanDetail",
          params: { verification: 1, type: item.m_type, id: item.m_hash },
        });
      } else {
        this.$router.push({
          name: "KeanggotaanDetail",
          params: { type: item.m_type, id: item.m_hash },
        });
      }
    },

    verification() {
      this.dialog = false;

      if (this.acceptReject === 2 && this.rejectReason.trim() === "") {
        this.$refs("reason").focus();
        return;
      }
      const requestBody = {
        m_hash: this.tmp.m_hash,
        status: this.acceptReject,
        reason: this.rejectReason,
      };

      axios
        .post("/v1/admin/member/verify-payment", requestBody)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.success(res.data.message);
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0][0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },

    toPaymentDetail(p) {
      this.tmp = p;
      this.getPaymentDetail(p.m_hash);
      this.dialog = true;
    },

    confirm(p) {
      // console.log(p)
      if (p === 0) {
        this.modal = false;
      } else {
        this.modal = false;
        this.verification();
      }
    },

    accept() {
      this.acceptReject = 1;
      this.modal = true;
    },

    reject() {
      this.acceptReject = 2;
      this.modal = true;
    },

    showImageDetail(p) {
      const url = [];
      url.push(p);
      this.$viewerApi({ images: url });
    },

    onChangePage(e) {
      this.page = e;
      this.initialize(e, 1, this.search);
    },

    onChangePage2(e) {
      this.page = e;
      this.initialize(e, 2, this.search2);
    },

    onChangePage3(e) {
      this.page = e;
      this.initialize(e, 3, this.search3);
    },

    onChangePage4(e) {
      this.page = e;
      this.initialize(e, 4, this.search4);
    },

    filterData() {
      this.initialize(this.page, 1, this.search);
    },

    filterData2() {
      this.initialize(this.page, 2, this.search2);
    },

    filterData3() {
      this.initialize(this.page, 3, this.search3);
    },

    filterData4() {
      this.initialize(this.page, 4, this.search4);
    },

    filterClear() {
      this.initialize(this.page, 1);
    },

    filterClear2() {
      this.initialize(this.page, 2);
    },

    filterClear3() {
      this.initialize(this.page, 3);
    },

    filterClear4() {
      this.initialize(this.page, 4);
    },
  },
};
</script>

<style lang="scss" scoped>
.txt12-gray50 {
  @extend .p-2;
  color: $gray-50;
}

.txt16-gray50 {
  @extend .p-1;
  color: $gray-50;
}

.txt16-black1 {
  @extend .p-1;
  color: $black-1;
}

.action-container {
  background: #f2f2f2;
}

.action-container:hover {
  background: #2b7dec;
}

.action-container:hover > .action-icon {
  color: #f2f2f2;
}

.action-icon {
  font-size: 16px;
  color: #2b7dec;
}

tbody > tr:hover {
  background-color: transparent !important;
}
</style>
